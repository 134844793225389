import React from "react"
import styled from "styled-components"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import { SupportPageStyles } from "../components/styles/SupportPageStyles"
import SEO from "../components/seo"
import SupportCard from "../components/SupportCard"
import DemoSection from "../components/DemoSection"

// Machine Images
import Orbitz from "../images/orbitz.png"
import K10StandardImage from "../images/K10-STANDARD-20D-TRACTION-w200.png"
import K10PA17Image from "../images/k10-compact-17d-padassist-x200.png"
import K12Image from "../images/k-12-white-bg.jpg"
import K16Image from "../images/k-16-white-bg.jpg"
import K19Image from "../images/k19-white.jpg"
import K25Image from "../images/k-25-white-bg.jpg"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const MachineContainer = styled.div`
  display: grid;
  max-width: 1400px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-content: space-between;
  justify-items: center;
`
const DisHeading = styled.h1`
  border-bottom: 2px solid red;
`
const RegBtnContainer = styled.div`
  display: grid;
  justify-content: center;
  .button {
    margin: 1rem 0rem;
    width: max-content;
    text-decoration: none;
  }
`

const SupportPage = props => {
  return (
    <>
      <SEO
        title="Support"
        description="Support for Kodiak flood scrubbers. Call us at 1-855-965-0011, start a live chat, or  download pdf manuals and specification sheets."
      />
      <MainLayout>
        <PageHeading pageHeading="Support" />
        <SupportPageStyles>
          <RegBtnContainer>
            <a className="button" href="/registration">
              Register a Machine
            </a>
          </RegBtnContainer>
          <h1>Downloads</h1>
          <PageLayout>
            <MachineContainer>
              <SupportCard
                cardHeader="Floor Machines"
                machineImage={Orbitz}
                showHood="none"
                showPM="none"
                brochureLink="../ORBITZ-SALES-SHEET.pdf"
                techLink="../Orbitz-Kodiak-Specs.pdf"
                partsLink="../KD-COMING-SOON.pdf"
                opsLink="../KD-COMING-SOON.pdf"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="Orbital Floor Scrubber"
              />

              <SupportCard
                cardHeader="K12 Floor Scrubber"
                machineImage={K12Image}
                hoodLink="../kodiak-underthehood.pdf"
                brochureLink="../kodiak-walk-behind-sm.pdf"
                techLink="../kodiak-k12-specsheet.pdf"
                partsLink="https://www.dropbox.com/s/x08bnughqhoole3/K-K12-P-EN.pdf?dl=0"
                opsLink="https://www.dropbox.com/s/emp4wx46gc1cx1l/K-K12-OP-EN.pdf?dl=0"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="K11 | walk behind floor scrubber"
                showPM="none"
              />

              <SupportCard
                cardHeader="K16 Floor Scrubber"
                machineImage={K16Image}
                hoodLink="../kodiak-underthehood.pdf"
                brochureLink="../kodiak-walk-behind-sm.pdf"
                techLink="../kodiak-k16-specsheet.pdf"
                partsLink="https://www.dropbox.com/s/ouqlikq65u87kdw/k-k16-p-en.pdf?dl=0"
                opsLink="https://www.dropbox.com/s/qskm3bx3jwor4uq/k-k16-op-en.pdf?dl=0"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="K16 | walk behind floor scrubber"
                PMLink="../K16-Series-PM.pdf"
              />

              <SupportCard
                cardHeader="K19 Floor Scrubber"
                machineImage={K19Image}
                hoodLink="../kodiak-underthehood.pdf"
                brochureLink="../kodiak-walk-behind-sm.pdf"
                techLink="../kodiak-k19-specsheet.pdf"
                partsLink="https://www.dropbox.com/s/zfg0vl9qagw72ug/K-K19-P-EN.pdf?dl=0"
                opsLink="https://www.dropbox.com/s/l8c0slaarlskkb6/K-K19-OP-EN.pdf?dl=0"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="K16 | walk behind floor scrubber"
                PMLink="../K16-Series-PM.pdf"
              />

              <SupportCard
                cardHeader="K25 Floor Scrubber"
                machineImage={K25Image}
                hoodLink="../kodiak-underthehood.pdf"
                brochureLink="../kodiak-walk-behind-sm.pdf"
                techLink="../kodiak-k25-specsheet.pdf"
                partsLink="https://www.dropbox.com/s/c4h1ep9rf8xszew/k-k25-p-en.pdf?dl=0"
                opsLink="https://www.dropbox.com/s/554b7q61rbrrknr/k-k25-op-en.pdf?dl=0"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="K25 | walk behind floor scrubber"
                PMLink="../K25-Series-PM.pdf"
              />
            </MachineContainer>
            <DisHeading>Discontinued Models</DisHeading>
            <MachineContainer>
              <SupportCard
                cardHeader="K10 Compact"
                machineImage={K10PA17Image}
                showHood="none"
                showPM="none"
                brochureLink="../k-k10s-k10c-BROCHURE-V20073.pdf"
                techLink="../K10-Kodiak-Specs.pdf"
                partsLink="../k-k10-c-p-en.pdf"
                opsLink="../k-k10-compact-op-en.pdf"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="K10 Compact | walk behind floor scrubber"
              />

              <SupportCard
                cardHeader="K10 Standard"
                machineImage={K10StandardImage}
                showHood="none"
                showPM="none"
                brochureLink="../k-k10s-k10c-BROCHURE-V20073.pdf"
                techLink="../K10-Kodiak-Specs.pdf"
                partsLink="../K-K10-S-P-EN.pdf"
                opsLink="../K-K10-STANDARD-OP-EN.pdf"
                warrantyLink="../RPS-WARRANTY-2020.pdf"
                alt="K10 Standard | walk behind floor scrubber"
              />
            </MachineContainer>
          </PageLayout>
        </SupportPageStyles>

        <DemoSection />
      </MainLayout>
    </>
  )
}

export default SupportPage
