import React from "react"
import styled from "styled-components"

import UTHIconImage from "../images/under-the-hood-h50.jpg"
import BrochureIconImage from "../images/brochure-h50.jpg"
import TechIconImage from "../images/tech-specs-icon-h50.jpg"
import PartsIconImage from "../images/parts-manual-h50.jpg"
import OpsIconImage from "../images/operators-manual-icon-h50.jpg"
import WarrantyIconImage from "../images/warranty-icon-h50.jpg"
import PMIcon from "../images/PM-Kodiak-Icon-50.png"
import mtIcon from "../images/mtLink.svg"

const SupportCardStyles = styled.div`
  .card-container {
    display: grid;
    border: 1px solid grey;
    max-width: 350px;
    min-height: 310px;
    margin: 1.5rem;
    .card-header {
      background-color: #424242;
      text-align: center;
      padding: 0.5rem;
      color: white;
    }
    .card-body {
      display: grid;
      padding: 0.5rem;
      grid-template-columns: 100%;
      justify-items: center;
      .machine-image-container {
        width: 170px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .downloads-container {
      text-align: center;
      .icon-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .image-container {
          position: relative;
          .image-container + .hover-text:hover {
            display: block;
            transition: ease-in-out;
            &:hover {
              transform: scale(1.1);
            }
          }
          img {
            padding: 0.3rem;
            transition: all ease-in-out 200ms;
            &:hover {
              transform: scale(1.1);
              filter: opacity(85%);
            }
          }
          .hover-text {
            position: absolute;
            top: 0;
            color: white;
            background-color: #ccd2c6;
            right: -50px;
            padding: 0.2rem;
            display: none;
          }
        }
      }
    }
  }

  #under-the-hood .hover-text:hover {
    display: block;
  }

  a {
    text-decoration: none;
    color: black;
  }
  p {
    text-align: center;
    font-size: 11px;
    width: 50px;
    margin: 0;
  }
`

const SupportCard = props => {
  return (
    <SupportCardStyles>
      <div className="card-container">
        <div className="card-header">{props.cardHeader}</div>
        <div className="card-body">
          <div className="machine-image-container">
            <img src={props.machineImage} alt={props.alt}></img>
          </div>
          <div className="downloads-container">
            <div className="icon-container">
              <div
                style={{ display: props.showHood }}
                className="image-container"
                id="under-the-hood"
              >
                <a href={props.hoodLink} target="_blank">
                  <img
                    title="Under the Hood"
                    src={UTHIconImage}
                    alt="Under the Hood download"
                  />
                  <p>Under the Hood</p>
                </a>
                <div className="hover-text">Under the Hood</div>
              </div>

              <div
                style={{ display: props.showBrochure }}
                className="image-container"
              >
                <a href={props.brochureLink} target="_blank">
                  <img
                    title="Brochure"
                    src={BrochureIconImage}
                    alt="Brochure download"
                  />
                  <p>Brochure</p>
                </a>

                <div className="hover-text">Under the Hood</div>
              </div>

              <div
                style={{ display: props.showTech }}
                className="image-container"
              >
                <a href={props.techLink} target="_blank">
                  <img
                    title="Tech Specs"
                    src={TechIconImage}
                    alt="Tech Specs download"
                  />
                  <p>Tech Specs</p>
                </a>

                <div className="hover-text">Under the Hood</div>
              </div>

              <div
                style={{ display: props.showParts }}
                className="image-container"
              >
                <a href={props.partsLink} target="_blank">
                  <img
                    title="Parts Manual"
                    src={PartsIconImage}
                    alt="Parts Manual download"
                  />
                  <p>Parts</p>
                </a>

                <div className="hover-text">Under the Hood</div>
              </div>

              <div
                style={{ display: props.showOps }}
                className="image-container"
              >
                <a href={props.opsLink} target="_blank">
                  <img
                    title="Operators Manual"
                    src={OpsIconImage}
                    alt="Operators Manual download"
                  />
                  <p
                    style={{
                      width: "70px",
                    }}
                  >
                    Operators Manual
                  </p>
                </a>

                <div className="hover-text">Under the Hood</div>
              </div>

              <div
                style={{ display: props.showWarranty }}
                className="image-container"
              >
                <a href={props.warrantyLink} target="_blank">
                  <img
                    title="Warranty"
                    src={WarrantyIconImage}
                    alt="Warranty download"
                  />
                  <p
                    style={{
                      width: "70px",
                    }}
                  >
                    Warranty
                  </p>
                </a>

                <div className="hover-text">Under the Hood</div>
              </div>

              <div
                style={{ display: props.showPM }}
                className="image-container"
              >
                <a href={props.PMLink} target="_blank">
                  <img
                    title="Preventative Maintenance Sheet"
                    src={PMIcon}
                    alt="Preventative maintenance download"
                  />
                  <p>PM Sheet</p>
                </a>

                <div className="hover-text">Under the Hood</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SupportCardStyles>
  )
}

export default SupportCard
