import styled from "styled-components"

export const SupportPageStyles = styled.div`
  h1 {
    text-align: center;
  }
  #image-container {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media all and (-ms-high-contrast: none) {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }

    @supports (-ms-ime-align: auto) {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      @media (max-width: 550px) {
        height: 120px;
      }

      @media all and (-ms-high-contrast: none) {
        margin: 0 50px;
      }

      @supports (-ms-ime-align: auto) {
        margin: 0 50px;
      }
    }
  }

  .download-link {
    text-align: center;
    font-size: 1.5em;

    @media (max-width: 550px) {
      font-size: 1em;
    }
  }

  .support-card-container {
    /* width: 80%; */
    margin: auto;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    justify-items: center;

    @media (max-width: 1120px) {
      grid-template-columns: 50% 50%;
    }

    @media (max-width: 730px) {
      grid-template-columns: 100%;
    }
  }
`
